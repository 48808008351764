import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import SubHeader from '../../../components/SubHeader';
import { breadcrumbRSA } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loadingSelector } from '../../../redux/Loader';
import { setNewPinCall, rsaServiceSelector, setNewPin } from '../../../redux/Rsa/Slice';
import styles from './SetNewPin.module.scss';
import LinearProgress from '@mui/material/LinearProgress';

export default function SetNewPin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loaderDetails } = useSelector(loadingSelector);
    const { setPinStatus } = useSelector(rsaServiceSelector);
    const { register, watch, handleSubmit, formState: { errors } } = useForm();
    useEffect(() => {

    }, [dispatch])

    const onSubmitNewPin = (data) => {
        dispatch(setNewPinCall(data.newpin, data.confirmnewpin));;
    }

    const handleNewPinKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            $("#confirmnewpin").focus();
        }
    }

    const handleConfirmPinKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            $("#pinSubmit").click();
        }
    }

    const [isCollapse, setIsCollapse] = useState(false);

    function toggleCollapse(val) {
        setIsCollapse(!val);
    }

    return (
        <>
            {(() => {
                if (setPinStatus) {
                    dispatch(setNewPin(""));
                    return navigate('/');
                }
                else {
                    return (
                        <>
                            {loaderDetails && <LinearProgress color="inherit" />}
                            <SubHeader breadcrumbs={breadcrumbRSA} />
                            <FormProvider onSubmit={e => e.preventDefault()} >
                                <Box className="bg-panel">
                                    <Box className="card-panel">
                                        <Box className="follow-instruction">
                                            <h3 className={styles['token-head']}>Set new PIN</h3>

                                            <FormControl sx={{ width: 'calc(min(100%, 38rem))' }}>
                                                <InputLabel htmlFor="newpin">Enter New PIN</InputLabel>
                                                <OutlinedInput
                                                    id="newpin"
                                                    type="password"
                                                    label="Enter New PIN"
                                                    error={!!errors["newpin"]}
                                                    onKeyDown={handleNewPinKeyDown}
                                                    aria-describedby="component-error-text"
                                                    {...register("newpin", {
                                                        required: {
                                                            value: true,
                                                            message: 'New PIN is required',
                                                        },
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9]{8}$/i,
                                                            message: "Must be exactly 8 alphanumeric characters"
                                                        }
                                                    })}
                                                />
                                                <Box sx={{ height: '50px' }}>
                                                    {errors.newpin && <FormHelperText error={errors.newpin} id="newpin-error-text">{errors.newpin.message}</FormHelperText>}
                                                </Box>
                                            </FormControl>

                                            <FormControl sx={{ width: 'calc(min(100%, 38rem))' }}>
                                                <InputLabel htmlFor="confirmnewpin">Confirm New PIN</InputLabel>
                                                <OutlinedInput
                                                    id="confirmnewpin"
                                                    type="password"
                                                    label="Confirm new PIN"
                                                    error={!!errors["confirmnewpin"]}
                                                    onKeyDown={handleConfirmPinKeyDown}
                                                    aria-describedby="component-error-text"
                                                    {...register("confirmnewpin", {
                                                        required: {
                                                            value: true,
                                                            message: 'Confirm New PIN is required',
                                                        },
                                                        validate: (val) => {
                                                            if (watch('newpin') !== val) {
                                                                return "New PIN and Confirm New PIN do not match";
                                                            }
                                                            return;
                                                        },
                                                    })}
                                                />
                                                <Box sx={{ height: '30px' }}>
                                                    {errors.confirmnewpin && <FormHelperText error={errors.confirmnewpin} id="confirmnewpin-error-text">{errors.confirmnewpin.message}</FormHelperText>}
                                                </Box>
                                            </FormControl>

                                        </Box>
                                        <div role="button" data-toggle="collapse" data-target="#criteria" onClick={() => toggleCollapse(isCollapse)}>
                                            {isCollapse && <div id="show-btn" className="collapse-toggle-btn">Show new PIN criteria <div className="collapse-toggle-icon">+</div></div>}
                                            {!isCollapse && <div id="hide-btn" className="collapse-toggle-btn collapse">
                                                Hide new PIN criteria's <div className="collapse-toggle-icon">-</div>
                                            </div>}

                                        </div>
                                        {!isCollapse && <div id="criteria" className="collapse">
                                            <div className="text-left pwd-criteria">
                                                <div>*Must be exactly 8 characters long</div>
                                                <div>*Can contain only numbers, only letters, or a mix of both</div>
                                                <div>*Special characters are not allowed</div>
                                                <div>*Case insensitive (e.g., "abc123XY" is same as "ABC123xy")</div>
                                                <div>*Cannot reuse the last 3 PINs</div>
                                            </div>
                                        </div>}
                                    </Box>

                                    <div className="btn-container" >
                                        <input data-testid="Cancel" type="submit" className="button-secondary" value="Cancel" onClick={() => { navigate("/apps"); }} />
                                        <input id="pinSubmit" data-testid="Submit" type="submit" className="button-primary shift-right" value="Submit" onClick={handleSubmit(onSubmitNewPin)} />
                                    </div>
                                </Box>
                            </FormProvider >
                        </>
                    )
                }

            })()}
        </>

    )
}

