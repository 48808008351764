import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selfServiceSelector, validateToken } from "../redux/PasswordReset/Slice";
import PropTypes from 'prop-types';

const propTypes = {
    children: PropTypes.any,
};

const ProtectedRoute = ({ children }) => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector(selfServiceSelector);
    const isActive = window.localStorage.getItem('isActive');
    window.localStorage.setItem("ParamVal", "isActive:" + isActive + " isAuthenticated:" + isAuthenticated);
    useEffect(() => {
        dispatch(validateToken());
    }, [dispatch]);

    if (isAuthenticated !== null) {
        if (isActive && isAuthenticated) {
            return children;
        }
        else {
            return <Navigate to="/?unauthorized" />;
        }
    }
    return null;
}

ProtectedRoute.propTypes = propTypes;
export default ProtectedRoute;
