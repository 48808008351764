import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { WfButton } from '../../../components';

const propTypes = {
    responseCode: PropTypes.any,
    setActiveStrips: PropTypes.any,
    setStripLabel: PropTypes.any,
    setHeadMessage: PropTypes.any,
    resetPassword: PropTypes.any,
};

ResponseCode.propTypes = propTypes;

export function ResponseCode({ responseCode, setActiveStrips, setStripLabel, setHeadMessage, resetPassword, loadTile }) {

    useEffect(() => {
        setActiveStrips(3);
        setStripLabel('Response');
        setHeadMessage("Generating Temporary Password");
    }, []);

    return (
        <div>
            <div className="bg-panel">
                <div className="card-panel">
                    <div className="step">
                        <span>Step 4</span>
                    </div>
                    <div className="step-instruction">
                        Enter the 'Response' into your wells Fargo device 'Response' field and Click 'Login'.
                    </div>

                    <div className="display-group">
                        <label htmlFor="code">Response</label>
                        <div className="code">
                            <label>{responseCode}</label>
                        </div>
                    </div>
                </div>

                <Box className="btn-container">
                    <Box className='btn-wrapper'>
                        <WfButton type='button' variant='secondary-btn' text='Back' onClick={() => { loadTile("ChallengeData2") }} ></WfButton>
                    </Box>
                    <Box className='btn-wrapper'>
                        <WfButton data-testid="resetPWDBtn" type='button' variant='contained' text='Next' onClick={() => resetPassword()}></WfButton>
                    </Box>
                </Box>
            </div>
        </div>
    );
}

