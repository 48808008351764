import React from 'react';
import { Box, Toolbar, Button } from '@mui/material';
import styles from './Header.module.scss';
import logo from '../../assets/Images/Logo_White.svg';
import { styled } from '@mui/material/styles';
import LogOff from '../../components/LogOff';
import { useDispatch } from 'react-redux';
import { validateTokenAndRedirect } from "../../redux/PasswordReset/Slice";

const CustomToolbar = styled((props) => <Toolbar {...props} />)(
    () => ({
        minHeight: '0!important',
        padding: '0!important',
    }),
);
/**
 * Application Header component according to wf standards
 * @returns Header component
 */
const Header = () => {
    const dispatch = useDispatch();

    const validateAndRedirect = () => {
        dispatch(validateTokenAndRedirect());
    }

    return (
        <Box className={styles['headerContainer']}>
            <CustomToolbar className={styles['navbar']}>
                <Box className={styles['brandContainer']}>
                    <Button variant='text' onClick={() => validateAndRedirect()} >
                        <img alt="software type" src={logo} className={styles['logo-img']}></img>
                    </Button>
                    <Box onClick={() => validateAndRedirect()} className={styles["sub-logo-text"]}>
                       Self Service Portal
                    </Box>
                </Box>
                <Box>
                    <LogOff />
                </Box>
            </CustomToolbar>
        </Box>
    );
}

export default Header;
