import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

const propTypes = {
    totalStrips:PropTypes.any,
    activeStrips:PropTypes.any,
    stripLabel:PropTypes.any,
    stripsCls:PropTypes.any,
};

TileStrips.propTypes = propTypes;

export function TileStrips({ totalStrips, activeStrips, stripLabel, stripsCls }) {
    return (
        <div className="strip-container">
            <Grid className="strips" >
                {createStrip(totalStrips, activeStrips, stripsCls)}
            </Grid>
            <div className="strip-lable-container">
                <div>{stripLabel}</div>
            </div> 
        </div>
    );
}

function createStrip(totalStrips, activeStrips, stripsCls) {
    const strips = [];
    for (let i = 0; i < totalStrips; i++) {
        let redHRCls = '';
        if (i < activeStrips) {
            redHRCls = 'red-hr';
        }
        strips.push(<div key={i} className={stripsCls + " strip"}><hr className={"hr-custom " + redHRCls} /></div>);
    }
    return strips;
}
