import React from 'react';
import { Box, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { SubHeader } from '../../../components';
import { breadcrumbMainApp } from '../../../constants';
import OprCardDetails from '../../../components/OprCard/index';
import prSrc from '../../../assets/Images/pass_reset.svg';
import rsaSrc from '../../../assets/Images/secure_id_icon.svg';
import PROptionMenus from '../../PasswordReset/PROption/index';
import RsaOptionMenus from '../../RSA/RsaOption/index';
import styles from './DWPSApps.module.scss';

export default function DWPSApps() {
    const enableRSATile = process.env.REACT_APP_ENABLE_RSA_TILE;
    return (
        <>
            <Helmet>
                <title>Self Service Portal - Apps</title>
            </Helmet>
            <Box className={styles['card-container']} sx={{ display: 'flex', marginTop: '0px', paddingTop: '0px', justifyContent: 'center' }}>
                <Grid sx={{
                    justifyContent: 'center', alignItems: 'center', height: '35rem', maxWidth: '58rem', display: 'block', textAlign: 'center', padding: '1rem', borderRadius: '4px',
                }}>
                    <Grid className={styles['card-head']}> What would you like to do today?</Grid>
                    <Grid className={styles['card-detail']}>Select an option below to manage your accounts.</Grid>
                    <Grid >
                        <Grid item>
                            <Grid item style={{ maxWidth: '343px', paddingBottom: '10px' }}>
                                <OprCardDetails
                                    CardName="Network Account (AD-ENT)"
                                    CardContent="Manage your Wells Fargo network password and access"
                                    children={<PROptionMenus />}>
                                </OprCardDetails>
                            </Grid>
                            {enableRSATile === 'true' &&
                                <Grid item style={{ maxWidth: '343px', paddingBottom: '10px' }}>
                                    <OprCardDetails
                                        CardName="RSA Security"
                                        CardContent="Manage your RSA authenticator and token"
                                        children={<RsaOptionMenus />}
                                    >
                                    </OprCardDetails>
                                </Grid>
                            }
                        </Grid>

                    </Grid>
                </Grid>

            </Box>
        </>
    )
}