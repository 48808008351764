import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { WfButton } from '../../../components';

const propTypes = {
    loadTile:PropTypes.any,
    setActiveStrips:PropTypes.any,
    setStripLabel:PropTypes.any,
};

GetStarted.propTypes = propTypes;

export function GetStarted({ loadTile, setActiveStrips, setStripLabel }) {

    useEffect(() => {
        setActiveStrips(1);
        setStripLabel('Forgot Password')
    }, []);

    function loadChallengeData2() {
        loadTile("ChallengeData2");
    }

    return (
        <Box>
            <Box>
                <Box className="bg-panel">
                    <Box className="card-panel">
                        <Box className="step">
                            <span>Step 1</span>
                        </Box>
                        <Box className="step-instruction">
                            Using your Wells Fargo device, enter 'sdhelp' in the username field and select Forgot Password.
                        </Box>
                        <Box className="step">
                            <span>Step 2</span>
                        </Box>
                        <Box className="step-instruction">
                            Select Challenge Response on the next screen on your Wells Fargo device.
                        </Box>
                    </Box>
                    <Box className="btn-container">
                        <Box className='btn-wrapper' >
                            <WfButton data-testid="Yes" type='button' variant='contained' text='Next' onClick={() => loadChallengeData2()}></WfButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}


