import React, { useState, useEffect } from 'react';
import encryptionImg from '../../../assets/Images/EncryptionScreen.png';
import ConfirmationBox from '../../../components/ConfirmationBox/index';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { getItem, setItem } from '../../../util/localStorage';
import PropTypes from 'prop-types';
import DisclaimerDialog from './Disclaimer/index';
import { UserType } from '../../../constants';
import { WfButton } from '../../../components';

const propTypes = {
    loadTile: PropTypes.any,
    setActiveStrips: PropTypes.any,
    setTotalStrips: PropTypes.any,
    SetStripsCls: PropTypes.any,
    setIsSecureDoc: PropTypes.any,
    resetPassword: PropTypes.any,
};

InitialPage.propTypes = propTypes;

export function InitialPage({ loadTile, setActiveStrips, setTotalStrips, SetStripsCls, setIsSecureDoc, resetPassword }) {

    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
    const [imageloaded, setImageloaded] = useState(false);
    const [disclaimerOpen, setDisclaimeropen] = useState(true);


    useEffect(() => {
        setActiveStrips(1);
    }, []);

    const username = getItem('userFirstName');

    function resetPasswordDirectly() {
        setItem("uType", UserType.bitLocker);
        setOpenConfirmationBox(true);
    }

    function resetPasswordWithChallengeCode() {
        setItem("uType", UserType.winMagic)
        loadTile("GetStarted");
    }

    function handleConfirmationBoxCancel() {
        setOpenConfirmationBox(false);
    }

    function handleProceed() {
        setOpenConfirmationBox(false);
        proceedNext();
    }

    function proceedNext() {
        setIsSecureDoc(false);
        SetStripsCls('strip-3')
        setTotalStrips(3);
        resetPassword();
    }

    const onLoad = () => {
        setImageloaded(true);
    };

    return (
        <Box>
            <ConfirmationBox data-testid="conmodal"
                openConfirmationBox={openConfirmationBox}
                handleConfirmationBoxCancel={handleConfirmationBoxCancel}
                handleProceed={handleProceed}
                dialogContentText={"Your are proceeding to reset your password and generate a temporary password. Do you wish to continue?"}
            />

            <DisclaimerDialog data-testid="disclaimerDialog" dialogOpen={disclaimerOpen} close={() => { setDisclaimeropen(false) }}></DisclaimerDialog>
            {!disclaimerOpen &&
                <Box className="bg-panel">
                    <Box className="card-panel">
                        <Box className="follow-instruction">
                            <Box className="name-initial">Hi {username}!</Box>
                            {!imageloaded ? <Skeleton width="100%">
                                <Box>.</Box>
                            </Skeleton> :
                                <Box>
                                    Do you see the pre-boot login screen on your Wells Fargo device? Refer image below.
                                </Box>}
                            <Box className="encryption-img-container" >
                                {!imageloaded && <Skeleton className="encryption-img" height="20rem" animation="wave" variant="rectangular" />}
                                <Box sx={{ display: !imageloaded ? 'none' : 'flex', justifyContent: 'center' }}>
                                    <img onLoad={() => onLoad()} role="presentation" alt="Pre-boot login page with forgot password heading and username/password input fields" className="encryption-img" src={encryptionImg} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box className="btn-container" >
                        <Box className='btn-wrapper'>
                            <WfButton data-testid="No" type='button' variant='secondary-btn' text='No' onClick={() => resetPasswordDirectly()}></WfButton>
                        </Box>
                        <Box className='btn-wrapper' >
                            <WfButton data-testid="Yes" type='button' variant='contained' text='Yes' onClick={() => resetPasswordWithChallengeCode()}></WfButton>
                        </Box>
                    </Box>
                </Box>
            }
        </Box>
    );
}
