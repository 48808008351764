import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import WF_LOGO from '../../../assets/Images/WF_Logobox.svg';
import { WfButton } from '../../../components';
import { Helmet } from "react-helmet";
import alert from '../../../assets/Images/messaging_icon.svg';
import $ from 'jquery';
import MuiAlert from '@mui/material/Alert';
import { useParams, useNavigate } from "react-router-dom";
import { getJWTToken, selfServiceSelector, validateToken, runOktaAuthentication } from "../../../redux/PasswordReset/Slice";
import { useSelector, useDispatch } from 'react-redux';
import { showErrorNotification, showSuccessNotification, showInfoNotification } from '../../../redux/Notification';
import { loadingSelector } from '../../../redux/Loader';
import Skeleton from '@mui/material/Skeleton';
import { getItem, setItem } from '../../../util/localStorage';
import moment from 'moment';
import styles from './Login.module.scss';
import { encode as base64_encode } from 'base-64';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Login() {
    const BaseURL = process.env.REACT_APP_API_BASE_URL;
    const CaptchaURL = process.env.REACT_APP_CAPTCHA_URL;
    const { loaderDetails } = useSelector(loadingSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showNoValidEmail, setshowNoValidEmail] = useState(false);
    const [hardLoader, setHardloader] = useState(false);
    const [emailId, setEmailId] = useState('');
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const { sessionout } = useParams();
    const [open, setOpen] = useState(sessionout);
    const [errorMessage, setErrorMessage] = useState("");
    const [sessionId, setSessionId] = useState(null);
    const isActive = window.localStorage.getItem('isActive');
    const { isAuthenticated } = useSelector(selfServiceSelector);
    const urlParams = new URLSearchParams(window.location.search);
    const [userText, setUserText] = useState("");
    const handleUserKeyPress = useCallback(event => {
        const { key, keyCode } = event;
        if (keyCode === 13) {
            setUserText(prevUserText => `${prevUserText}${key}`);
        }
    }, []);
    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    useEffect(() => {
        $('.nucaptcha-answer').on('keypress', function (e) {
            let key = e.which;
            if (key === 13) {
                validateEmail(e);
            }
        });
    });

    useEffect(() => {
        if (open) {
            dispatch(showInfoNotification("You're being redirected to login page due to session time out."));
            setOpen(!open);
            return navigate('/');
        }
        else if (urlParams.has("state") && urlParams.has("code")) {
            setHardloader(true);
            return dispatch(getJWTToken(urlParams.get("state"), urlParams.get("code")));
        }
        else if (urlParams.has("error")) {
            dispatch(showErrorNotification(urlParams.get("error")));
            return navigate('/');
        }
        else if (urlParams.has("unauthorized")) {
            window.localStorage.setItem("urlParams", urlParams);
            let message = "This application is restricted to authorized users";
            if (!isAuthenticated) {
                message = message + "!";
            }
            if (isActive === null) {
                message = message + ".";
            }
            else if (isActive === 'false') {
                message = message + " .";
            }

            dispatch(showErrorNotification(message));
            return navigate('/');
        }
        else if (urlParams.has("tokenexpire")) {
            dispatch(showErrorNotification("Re-Login required to continue further."));
            return navigate('/');
        }
        else if (urlParams.has("logout")) {

            dispatch(showSuccessNotification("you have successfully logged out."));
            return navigate('/');
        }
        else {
            return navigate('/');
        }
    }, []);

    useEffect(() => {
        dispatch(validateToken());

        $(function () {
            $('#nucaptcha-answer').addClass('custom-txtbox');
            $('#media-container').addClass('captcha-img');
            $('#nucaptcha-answer').attr("placeholder", "Enter Captcha");
        });

        fetch(`${BaseURL}api/v1/user/sessionId`)
            .then(function (body) {
                return body.text();
            }).then(function (data) {
                setSessionId(data)
                window.localStorage.setItem('sessionId', data);
                let captchaBaseUrl = CaptchaURL;
                let idx = captchaBaseUrl.indexOf("/ATADUN");
                let jsURI = captchaBaseUrl.substring(0, idx);
                (function (w, d, s, u, q, js, fjs, nds) {
                    nds = w.ndsapi || (w.ndsapi = {});
                    nds.config = {
                        q: [],
                        ready: function (cb) {
                            this.q.push(cb)
                        }
                    };
                    js = d.createElement(s);
                    fjs = d.getElementsByTagName(s)[0];
                    js.src = jsURI + "/jenny/nd";
                    fjs.parentNode.insertBefore(js, fjs);
                    js.onload = function () {
                        nds.load(captchaBaseUrl);
                    }
                }(window, document, "script", captchaBaseUrl));
                let nds = window.ndsapi;
                nds.config.ready(function () {
                    nds.setPlacement("EmpSelfService");
                    nds.setPlacementPage(1);
                    nds.setSessionId(data);
                });
            }).catch(e => {
                dispatch(showErrorNotification("Unable to reach API.Please try After Some time."));
            });
        $('#PRRoot').html('<script id="nucaptcha-template" type="text/x-nucaptcha-template">${PlayerStart} ${Media} <p id="new-challenge-link">${CommandNewChallenge} ${CommandPlayerMode}</p>' +
            '<div id="my-nucaptcha-answer-wrapper">${DirectionsVerbose} ${InputAnswer} </div>${PlayerEnd}</script>');
    }, []);

    const validateEmail = (event) => {
        event.preventDefault();
        let form = document.getElementById('myForm');
        let formData = new FormData(form);
        let formObject = Object.fromEntries(formData.entries())
        formObject['nds-pmd'] = base64_encode(formObject['nds-pmd']);
        if (emailId?.match(isValidEmail)) {
            setshowNoValidEmail(false);
            setItem('username', emailId);
            let URL = BaseURL.concat('api/v1/user/captcha');

            $.ajax({
                type: "POST",
                url: URL,
                data: JSON.stringify(formObject),
                processData: false,
                contentType: "application/json",
                headers: {
                    "SessionId": window.localStorage.getItem('sessionId'),
                    "Email": emailId,
                    "UserName": userInfo().userName,
                    "EmpId": userInfo().empId,
                    "Uid": userInfo().uid,
                    "UserType": userInfo().userType,
                }
            }).done(function (data) {
                if (data.includes("div")) {
                    $('#captchaCard').removeClass('hideDiv');
                    $('#captchaCard').addClass('showDiv');
                    $('#captchaCard').html(data);
                }
                else {
                    dispatch(runOktaAuthentication(emailId));
                };
            });
        }
        else {
            if (emailId !== "") {
                setErrorMessage("Please enter a valid email id");
            }
            else {
                setErrorMessage("Please enter correct email id");
            }
            setshowNoValidEmail(true);
        }
    }

    const handleEmailEnter = (event) => {
        setEmailId(event.target.value);
        setshowNoValidEmail(false);
        if (event.key === 'Enter') {
            validateEmail(event);
        }
    }

    function userInfo() {
        let result = {}
        var userName = "";

        if (getItem('username'))
            result.email = getItem('username')

        if (getItem('userFirstName'))
            userName = getItem('userFirstName')

        if (getItem('userLastName'))
            result.userName = userName + " " + getItem('userLastName')

        if (getItem('empId'))
            result.empId = getItem('empId')

        if (getItem('uid'))
            result.uid = getItem('uId')

        if (getItem('uType'))
            result.userType = getItem('uType')

        return result;
    }

    return (
        <>
            <Helmet>
                <title>Self Service Portal - Login</title>
                <Helmet><script src="~/lib/jquery/dist/jquery.js"></script></Helmet>
            </Helmet>
            {(() => {
                if (isActive && isAuthenticated) {
                    return navigate("/apps");
                }
                else {
                    return (
                        <>
                            {((loaderDetails || hardLoader) &&
                                <Box sx={{ display: 'flex', margin: '1rem', justifyContent: 'center' }}>
                                    <Skeleton animation="wave" height="35rem" width="58rem" style={{ marginBottom: 6, transform: "inherit" }} />
                                </Box>
                            )}
                            {(
                                <Box sx={{ display: (loaderDetails || hardLoader) ? 'none' : 'flex', margin: '1rem', justifyContent: 'center' }}>
                                    <Grid container sx={{
                                        justifyContent: 'center', alignItems: 'center', maxWidth: '58rem', display: 'block', textAlign: 'center', borderRadius: '4px',
                                    }}>
                                        <div id="PRRoot"></div>
                                        {sessionId &&
                                            <Grid container className={styles["account-container"]} style={{ display: 'flex', padding: '20px', textAlign: 'left' }}>
                                                <Grid className={styles['head-title']}>
                                                    Welcome to Account Self Service
                                                </Grid>
                                                <Grid className={styles['sub-head-title']}>
                                                    Securely manage your network access and credentials from any devices, anywhere.
                                                </Grid>

                                                <form action="post" className={styles["my-form"]} id="myForm" onSubmit={(e) => validateEmail(e)}>
                                                    <Grid className={styles['email-lbl-text']}>
                                                        Wells Fargo Email
                                                    </Grid>
                                                    <Grid className={styles["email-txt-container"]}>
                                                        <FormControl style={{ width: '100%' }}>
                                                            <OutlinedInput
                                                                className={styles['email-input-text']}
                                                                id="component-error"
                                                                aria-describedby="component-error-text"
                                                                placeholder="Enter your Wells Fargo email"
                                                                value={emailId}
                                                                name="UserName"
                                                                onChange={(e) => handleEmailEnter(e)}
                                                                error={showNoValidEmail}
                                                                inputProps={{ "type": "text", }}
                                                            />
                                                            {showNoValidEmail && <FormHelperText style={{ marginTop: '0px' }} error={showNoValidEmail} id="component-error-text">{errorMessage}</FormHelperText>}
                                                        </FormControl>
                                                    </Grid>
                                                    <input type="hidden" name="SessionId" value={sessionId} />

                                                    <Grid id="captchaCard" className="card-panel captchaControls hideDiv" />
                                                    <Grid className={styles['btn-container']}>
                                                        <WfButton data-testid="btnEmail" type='button' variant='contained' text='Next'
                                                            onClick={(e) => validateEmail(e)}></WfButton>
                                                    </Grid>
                                                </form>
                                            </Grid>
                                        }
                                        <Grid className={styles['guidance']} >
                                            <Grid className={styles['gui-head']} >
                                                Important requirements
                                            </Grid>
                                            <Grid className={styles['gui-content']}>
                                                <Grid className={styles['gui-content-detail']}>
                                                    To use this service, you must have one of these multi-factor authenticator apps configured to your moble device.
                                                </Grid>

                                                <li>RSA Authenticator</li>
                                                <li>HYPR</li>
                                            </Grid>
                                            <Grid className={styles['gui-head']}>
                                                Need help ?
                                            </Grid>
                                            <Grid className={styles['gui-content']}>
                                                <Grid className={styles['gui-content-detail']}>
                                                    Contact technical support if you:
                                                </Grid>
                                                <div><li>Don't have either multi-factor authenticator app installed</li></div>
                                                <li>Have a multi-factor app installed but it's not working correctly</li>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Box>
                                    </Box>
                                </Box>
                            )}
                        </>
                    )
                }
            })()}
        </>
    )

}
