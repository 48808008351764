import React from 'react';
import PropTypes from 'prop-types';
import { easings } from 'react-animation';
import 'react-animation/dist/keyframes.css';
import { Card, CardContent, Grid, Box } from '@mui/material';
import { Button } from '../../components/Button';
import styles from './Card.module.scss';
import Avatar from '@mui/material/Avatar';

const propTypes = {
    CardName: PropTypes.string,
    TitleOnClick: PropTypes.func
};

const animateStyle = {
    animation: `fade-in-up ${easings.easeInOutExpo} 150ms`
};

/**
 * React Card component used to render the Product card details in home page
 * @param {*} props : expecting Product name , publisher, and description as input from the Parent component
 * @returns An animated card component visualizes the product/software minimalistic details
 */
const OprCardDetails = (props) => {
    return (
        <Card className={`${styles['card']} ${animateStyle}`} >
            <CardContent className={styles['card__content']}>
                <Grid container columns={24} sx={{ flexWrap: 'nowrap' }}>

                    <Grid item className={styles['card__item']} style={{ width: '100%', alignContent: 'center', display: 'grid' }}>
                        <Grid className={styles['card_name']}>
                            {props.CardName}
                        </Grid>
                        <Grid className={styles['card_content']} >
                            {props.CardContent}
                        </Grid>
                        <Grid className={styles['children-container']}>
                            {props.children}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

OprCardDetails.propTypes = propTypes;
export default OprCardDetails;