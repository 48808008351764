import React, { useState, useEffect, useCallback } from 'react';
import { Popover, Button, Backdrop, Box, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './PROption.module.scss';
import ConfirmationBox from '../../../components/ConfirmationBox/index';
import { unlockADAccount } from '../../../redux/PasswordReset/Slice';
import { useNavigate } from 'react-router-dom';
import { showSuccessNotification } from '../../../redux/Notification';
import { WfButton } from '../../../components';

export default function PROptionMenus() {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openUnlockConfBox, setOpenUnlockConfBox] = useState(false);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }
    function unlockPRAccount() {
        setOpenUnlockConfBox(true);
        setAnchorEl(null);
    }
    function handleUnlockCancel() {
        setOpenUnlockConfBox(false);
    }
    function handleUnlockAccount() {
        dispatch(unlockADAccount());
        dispatch(showSuccessNotification("Unlocking your account.."));
        setAnchorEl(null);
        setOpenUnlockConfBox(false);
    }

    const PasswordResetLoad = () => {
        navigate(`/passwordreset/landingpage`);
    }

    return (
        <>
            <ConfirmationBox data-testid="unlock-conf"
                openConfirmationBox={openUnlockConfBox}
                handleConfirmationBoxCancel={handleUnlockCancel}
                handleProceed={handleUnlockAccount}
                dialogContentText={"You are about to unlock your AD Account. Are you Sure want to continue?"}
            />

            <div>
                <Grid>
                    <Grid style={{ padding: '10px 0px' }}>
                        <WfButton data-testid="btnEmail" type='button' variant='contained' text='Reset Password'
                            onClick={() => PasswordResetLoad()}></WfButton>
                    </Grid>
                    <Grid style={{ padding: '10px 0px' }}>
                        <WfButton data-testid="btnEmail" type='button' variant='secondary-btn' text='Unlock Account'
                            onClick={() => unlockPRAccount()}></WfButton>
                    </Grid>
                </Grid>
            </div>

        </>
    );
}